<template>
  <a-spin :spinning="isLoading">
    <div class="col-span-7 mt-16px pb-16px mx-3">
      <div class="mt-16px gap-16px" v-if="data">
        <div class="relative bg-ems-gray800 h-89vh p-20px">
          <div class="flex justify-between border-b border-ems-gray700">
            <div>
              <div class="bg-ems-main2 rounded-8px mb-5px w-[fit-content]">
                <div
                  v-if="tracingObjectDetail.name || tracingObjectDetail.id"
                  class="h-full text-white-a500 px-10px py-4px hover:cursor-pointer"
                  @click="
                    copyContent(
                      tracingObjectDetail.name
                        ? tracingObjectDetail.name
                        : tracingObjectDetail.id
                    )
                  "
                >
                  {{
                    tracingObjectDetail.name
                      ? tracingObjectDetail.name
                      : tracingObjectDetail.id
                  }}
                </div>
              </div>
              <div class="text-ems-white text-16px font-semibold leading-25px">
                {{ t('object_tracing.statistics_of_the_frequency') }}
              </div>
              <div class="text-ems-gray400 text-14px mt-13px">
                {{ t('object_tracing.the_total_appears') }}
              </div>
              <div class="text-ems-main2 text-28px font-semibold leading-44px">
                {{
                  tracingObjectDetail.frequency &&
                  tracingObjectDetail.frequency.total
                    ? tracingObjectDetail.frequency.total
                    : 0
                }}
              </div>
            </div>
          </div>
          <Chart
            ref="frequencyChart"
            :option="chartData"
            class-name="chart bg-evisa-gray50 rounded-lg pr-4"
            width="100%"
            :height="heightTb"
          ></Chart>
        </div>
      </div>
    </div>
  </a-spin>
  <a-modal
    :visible="previewVisible"
    :title="tracingObjectDetail.id"
    :footer="null"
    @cancel="handleCancel"
  >
    <img
      :alt="tracingObjectDetail.name"
      style="width: 100%"
      :src="previewUrl"
      loading="lazy"
    />
  </a-modal>
</template>
<script setup>
import { RELATIONSHIP, WARNING_LEVEL } from '@/config/Constant';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Chart from '@/view/trace-management/object-tracing/components/Chart.vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { copyContent } from '@/util/common-utils';
import { decryptData } from '@/dataService/dataService';
import { OBJECT_TYPE } from '../../../util/common-constant';
// import {TYPE_EVENT} from "@/util/common-constant";

const { t } = useI18n();
const route = useRoute();
const { state, dispatch } = useStore();
const tracingObjectDetail = computed(
  () => state.tracingObject.tracingObjectDetail
);
const dataChart = computed(() => state.tracingObject.dataChart);
const isLoading = computed(() => state.tracingObject.isLoading);
const listGroup = computed(() => state.ioGroup.listGroup);
const previewVisible = ref(false);
const previewUrl = ref('');
// const onShowPreviewImg = (url) => {
//   previewVisible.value = true;
//   previewUrl.value = url;
// };
const handleCancel = () => {
  previewVisible.value = false;
  previewUrl.value = '';
};
const chartData = reactive({
  tooltip: {
    backgroundColor: '#2B2A3A',
    formatter: function (params) {
      if (params.data.id) {
        const obj = tracingObjectDetail.value.frequency.statistic.find(
          (item) => item.profileUUID === params.data.id
        );
        const group = listGroup.value.find(
          (item) => item.id === obj.ioGroups[0]
        );
        const getRelationship = () => {
          let arr = [];
          for (let i in obj.relate) {
            if (obj.relate[i]) {
              arr.push(t(RELATIONSHIP[i]));
            }
          }
          return arr.length > 0 ? arr.join(' ,') : '';
        };
        return `<div class="flex gap-20px">
              <img src="${
                obj.imagesAvatar
                  ? obj.imagesAvatar
                  : require('@/static/img/no-img.png')
              }" class="rounded-8px w-[120px] h-[120px] flex-shrink-0 object-cover">
              <div class="flex flex-col gap-5px">
                <p class="text-18px font-semibold text-ems-gray100">${
                  params.data.name
                }</p>
                <div class="flex">
                  <span class="text-ems-gray300">${t(
                    'object_tracing.group'
                  )}:&nbsp;</span>
                  <span class="rounded-lg py-6px px-10px text-center mr-2 cursor-pointer text-ems-gray200
                  ${group ? '' : 'hidden'}
                  ${
                    group
                      ? WARNING_LEVEL[group.warningLevel - 1].color
                      : 'bg-ems-gray500'
                  }">
                  ${group && group.name}
                  </span>
                  <span class="rounded-lg py-6px px-10px text-center mr-2 cursor-pointer text-ems-gray200 bg-ems-gray500 ${
                    obj.ioGroups && obj.ioGroups.length > 1 ? '' : 'hidden'
                  }">
                  ...
                  </span>
                  <span class="text-ems-gray300 ${group ? 'hidden' : ''}">${t(
          'object_tracing.none'
        )}</span>
                </div>
                <span class="text-ems-gray300">${t(
                  'object_tracing.relationship2'
                )} ${
          getRelationship()
            ? `<span class="text-ems-main2 font-bold">${getRelationship()}</span>`
            : t('object_tracing.relationship-unknown')
        }</span>
                <span class="text-ems-gray300">${t(
                  'object_tracing.frequency'
                )} <span class="text-ems-main2 font-bold">${obj.count} ${t(
          'object_tracing.times'
        )}</span></span>
              </div>
              </div>`;
      }
      return '';
    },
  },
  series: [
    {
      name: t('object_tracing.frequency-appear'),
      type: 'treemap',
      data: [
        {
          value: 0,
          name: t('object_tracing.frequency-appear'),
          children: [],
        },
      ],
      visibleMin: 300,
      leafDepth: 2,
      breadcrumb: {
        show: false,
      },
      levels: [
        {
          itemStyle: {
            color: '#5B5B9F',
            borderColor: '#555',
            borderWidth: 4,
            gapWidth: 4,
          },
        },
        {
          colorSaturation: [0.8, 0.3],
          itemStyle: {
            color: '#5B5B9F',
            borderColorSaturation: 0.7,
            gapWidth: 2,
            borderWidth: 2,
          },
        },
        {
          colorSaturation: [0.8, 0.3],
          itemStyle: {
            color: '#5B5B9F',
            borderColorSaturation: 0.6,
            gapWidth: 1,
          },
        },
        {
          colorSaturation: [0.8, 0.3],
        },
      ],
    },
  ],
});
const heightTb = ref('calc(100vh - 250px)');
const frequencyChart = ref(null);
watch(
  () => [dataChart.value, frequencyChart.value],
  () => {
    chartData.series[0].data[0].value = tracingObjectDetail.value.frequency
      ? tracingObjectDetail.value.frequency.total
      : 0;
    chartData.series[0].data[0].children = dataChart.value.map((item) => ({
      name: item.name,
      value: item.value,
      id: item.id,
    }));
  }
);
const dateFrom = ref(null);
const dateTo = ref(null);
watch(
  () => [dateFrom.value, dateTo.value],
  () => {
    onChangeFilterDate();
  }
);
const onChangeFilterDate = () => {
  dispatch('tracingObject/getDetail', {
    id: route.query.id,
    params: {
      type: route.query.type,
      dateFrom: dateFrom.value
        ? dayjs(dateFrom.value).startOf('day').toDate()
        : null,
      dateTo: dateTo.value ? dayjs(dateTo.value).endOf('day').toDate() : null,
    },
  });
};

const connection = ref(null);
onMounted(async () => {
  if (route.query.id && route.query.typeText) {
    getDataDetail(route.query.id, route.query.typeText);
  }
  await dispatch('setTitleHeader', t('object_tracing.frequency_of_appearance'));
  await dispatch('ioGroup/getAllGroup');
  isLoading.value = true;
  // WebSocket
  connection.value = new WebSocket(
    `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
      state.auth.tokenInfo.accessToken
    }&id=${state.auth.userInfo.id}`
  );
  connection.value.onmessage = (event) => {
    const res = JSON.parse(event.data);
    if (res.type === 'warning') {
      mappingData(JSON.parse(event.data));
      getDataSocket(JSON.parse(event.data));
    }
  };
  connection.value.ondisconnect = async () => {
    await dispatch('getUserInfo');
    connection.value = new WebSocket(
      `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
        state.auth.tokenInfo.accessToken
      }&id=${state.auth.userInfo.id}`
    );
  };
});

const data = ref({
  name: '',
});
const mappingData = (response) => {
  if (response.type === 'warning') {
    data.value.type = response.data.event.data.type;
    data.value.id = response.data.io.id;
    data.value.createdAt = response.data.event.createdAt;
    data.value.imageUrl = response.data.event.data.imageUrl;
    data.value.imageCropUrl = response.data.event.data.imageCropUrl;
    data.value.imageLicensePlateUrl =
      response.data.event.data.imageLicensePlateUrl;
    data.value.latitude = response.data.event.data.cameraLatitude;
    data.value.longitude = response.data.event.data.cameraLongitude;
    data.value.cameraName = response.data.event.data.cameraName;
    data.value.location = response.data.event.data.cameraLocation;
    data.value.characteristic = response.data.io.characteristic;
    data.value.ioGroups = response.data.io.ioGroups;
    // const metaData = JSON.parse(response.data.event.data.metaData);
    if (data.value.type === 'VEHICLE') {
      data.value.bienSo = response.data.io.licensePlate;
      data.value.classify = response.data.io.brandId;
      data.value.color = response.data.io.vehicleColor;
      data.value.owner = response.data.io.ownerVehicle;
      data.value.vehicleType = response.data.io.vehicleType;
    }
    if (data.value.type === 'HUMAN') {
      data.value.name = response.data.io.name;
    }
  }
};
const router = useRouter();
const getDataSocket = async (response) => {
  await getDataDetail(response.data.io.id, response.data.event.data.type);
};

const getDataDetail = async (id, type) => {
  await dispatch('tracingObject/getDetail', {
    id: id,
    params: {
      dateFrom: dayjs().subtract(30, 'day').startOf('day').toDate(),
      dateTo: dayjs().endOf('day').toDate(),
      type: OBJECT_TYPE[type] ? OBJECT_TYPE[type] : 1,
    },
  });
  await router.push({
    query: {
      id: id,
      type: OBJECT_TYPE[type] ? OBJECT_TYPE[type] : 1,
      typeText: type
    },
  });
};

onUnmounted(() => {
  connection.value.close();
});
</script>
<style lang="scss">
.information-tab .tag:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.information-tab .input-text-red .ant-input-sm .ant-input {
  --tw-text-opacity: 1;
  color: rgba(91, 91, 159, var(--tw-text-opacity));
}
.image-tracing-custom .ant-image {
  height: 100%;
  width: 100%;
}
.image-tracing-custom .ant-image-img {
  height: 100% !important;
}
img.ant-image-preview-img {
  display: inline-block;
}
.chart-prefix-datepicker .ant-picker {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
