<template>
  <div
    ref="chartDom"
    :class="className"
    :style="{ height: height, width: width }"
  />
</template>

<script setup>
import { init } from 'echarts';
import 'echarts/theme/macarons';
import VueTypes from 'vue-types';
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

const chart = shallowRef();
const chartDom = ref();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  className: String,
  width: VueTypes.bool.def('100%'),
  height: VueTypes.string.def('300px'),
  option: Object,
});

const handleResize = () => {
  chart.value.resize();
};

watch(
  () => props,
  (val) => {
    if (chart.value) {
      chart.value.setOption(val.option, true);
    }
  },
  { deep: true }
);

onMounted(() => {
  window.addEventListener('resize', handleResize);
  nextTick(() => {
    chart.value = init(chartDom.value);
    props.option.textStyle = {
      fontFamily: 'Saira',
      color: 'transparent',
      fontSize: '14px',
    };
    chart.value.on('click', (params) => {
      if (route.path === '/trace-management/object-tracing/detail') {
        router.push({
          path: '/trace-management/object-tracing/detail',
          query: {
            ...route.query,
            id: route.query.id,
            tab: 'location',
            profileUUID: params.data.id,
            type: route.query.type ? route.query.type : 1
          },
        });
      } else {
        router.push({
          path: '/trace-management/object-tracing/detail',
          query: {
            id: route.query.id,
            tab: 'location',
            profileUUID: params.data.id,
            type: route.query.type ? route.query.type : 1
          },
        });
      }
    });
    chart.value.setOption(props.option);
  });
});

onBeforeUnmount(() => {
  if (chart.value) {
    window.removeEventListener('resize', handleResize);
    chart.value.dispose();
    chart.value = undefined;
  }
});

defineExpose({ chart, handleResize });
</script>
