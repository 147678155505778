<template>
  <div>
    <Empty
      v-if="!showData"
      :description="t('common.no_data_displayed')"
      class="mt-[200px]"
    />
    <div
      v-else
      ref="formHistory"
      tabindex="0"
      class="form-history-map relative"
    >
      <div
        class="flex items-center justify-between gap-[20px] px-[16px] py-[20px] bg-ems-gray800"
      >
        <div class="flex items-center gap-[20px]">
          <div class="relative h-[34px]">
            <cds-select
              class="!w-[250px] h-[34px]"
              :placeholder="t('object_tracing.cam-group')"
              :options="groupCameras"
              v-model:value="formState.groupCameraIds"
              :allow-clear="true"
              :label-prop="'name'"
              :value-prop="'id'"
              @change="changeGroupCamera"
              multiple
              :maxTagCount="1"
              :max-tag-text-length="20"
            >
            </cds-select>
            <cds-feather-icons
              v-if="formState.groupCameraIds.length === 0"
              type="chevron-down"
              class="text-ems-gray400 absolute right-2 inline-flex items-center h-full h-[34px]"
            />
          </div>
          <div class="relative h-[34px]">
            <cds-select
              class="!w-[250px] h-[34px]"
              :placeholder="t('object_information.camera')"
              :options="cameras"
              v-model:value="formState.cameraIds"
              :allow-clear="true"
              :label-prop="'name'"
              :value-prop="'id'"
              multiple
              :maxTagCount="1"
              :max-tag-text-length="20"
            >
            </cds-select>
            <cds-feather-icons
              v-if="formState.cameraIds.length === 0"
              type="chevron-down"
              class="text-ems-gray400 absolute right-2 inline-flex items-center h-full h-[34px]"
            />
          </div>
          <cds-from-to-date
            v-model:dateFrom="formState.dateFrom"
            v-model:dateTo="formState.dateTo"
            :is-show-label="false"
            :allow-clear="true"
            class="from-to-date"
          />
        </div>
        <div class="flex items-center gap-[20px]">
          <ClipButton
            class="flex items-center text-sm text-ems-white text-nowrap h-[34px] !px-[30px]"
            :type="'secondary'"
            @click="resetData"
          >
            <cds-feather-icons
              type="rotate-cw"
              size="16"
              class="!text-ems-white mr-2"
            />
            {{ t('common.reset') }}
          </ClipButton>
        </div>
      </div>
      <div
        class="form-history-map mt-[16px] mr-[25px] ml-[16px] p-[20px] bg-ems-gray800"
      >
        <div>
          <div class="grid grid-cols-10 gap-[16px] mt-[22px]">
            <div class="col-span-7 h-[calc(100vh-400px)] relative">
              <div v-if="isLoading" class="spin bg-transparent">
                <a-spin />
              </div>
              <div v-if="!isLoading" class="relative">
                <LeafletMap
                  ref="travelMap"
                  class="!h-[calc(100vh-400px)]"
                  :markers="markersTravel"
                  :center="center"
                  :markerUser="markersTrinhSat"
                  @click-marker="onClickMarkerTravel"
                  :flightPath="flightPathTravel"
                />
                <div class="absolute top-[30px] left-[8px]">
                  <a-popover
                    placement="right"
                    overlay-class-name="popup-groups"
                  >
                    <template #content>
                      <div class="text-ems-white font-semi-bold">
                        {{ t('object_tracing.last-location') }}
                      </div>
                    </template>
                    <img
                      :src="require('@/static/img/icon/vtri-cuoi.png')"
                      alt="lastlocation"
                      width="43"
                      height="43"
                      class="cursor-pointer"
                      @click="moveLastLocation"
                    />
                  </a-popover>
                </div>
              </div>
            </div>
            <div
              class="col-span-3 rounded-[8px] border-[1px] border-solid border-ems-gray500 rounded-lg p-16px pr-0 h-[calc(100vh-400px)] overflow-hidden"
            >
              <TravelRoute
                ref="travelRoute"
                @selectItem="selectLocation"
                @on-search-data="onSearchLocation"
                :isLoading="isLoading"
                :showSearch="false"
                class="!h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, reactive, ref, watch, onMounted } from 'vue';
import Empty from '@/components/empty/index.vue';
import { useRoute, useRouter } from 'vue-router';
import GoongLineMap from '@/components/goong-line-map/index.vue';
import LeafletMap from '@/components/leaflet-map/index.vue';
import TravelRoute from '@/view/trace-management/object-tracing/components/TravelRoute.vue';
import { MARKER_TYPE, EVENT_STATUS_VALUE } from '@/util/common-constant.js';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { formatDate } from '@/util/common-utils';

const { t } = useI18n();
const { state, dispatch } = useStore();
const locationData = computed(() => state.tracingObject.locationData);
const groupCameras = computed(() => state.tracingObject.listGroupCamera);
const cameras = computed(() => state.tracingObject.listCameraByGroup);
const isLoading = ref(false);
const router = useRouter();
const route = useRoute();
const formHistory = ref();
const markersTravel = ref([]);
const travelMap = ref();
const travelRoute = ref();
const lastCamera = ref('');
const flightPathTravel = ref([]);
const lastLocation = ref({
  lat: 21.051448,
  lng: 105.8002,
});
const props = defineProps({
  showData: {
    default: true,
  },
});

onMounted(async () => {
  if (props.showData) {
    dispatch('tracingObject/getListGroupCamera');
    dispatch('tracingObject/getListCameraByGroups', []);
    await getData();
  }
});

const formState = reactive({
  groupCameraIds: [],
  cameraIds: [],
  dateFrom: null,
  dateTo: null,
  type: route.query.type
});
const changeGroupCamera = () => {
  dispatch('tracingObject/getListCameraByGroups', formState.groupCameraIds);
};
const getLastLocation = () => {
  if (locationData.value.length > 0) {
    lastCamera.value = locationData.value[0].camera.id;
    lastLocation.value.lat = locationData.value[0].camera.latitude;
    lastLocation.value.lng = locationData.value[0].camera.longitude;
  }
};
const resetData = () => {
  formState.groupCameraIds = [];
  formState.cameraIds = [];
  formState.dateFrom = null;
  formState.dateTo = null;
};
const getData = async () => {
  isLoading.value = true;
  await dispatch('tracingObject/getLocation', {
    id: route.query.id,
    params: formState,
  });
  getLastLocation();
  getDataTravelMap();
  isLoading.value = false;
  if (travelMap.value) {
    travelMap.value.setMarker(markersTravel.value);
    travelMap.value.updateLineString(flightPathTravel.value);
  }
};
const moveLastLocation = () => {
  if (locationData.value.length > 0) {
    travelRoute.value.selectItem(0, locationData.value[0]);
  }
};
const onClickMarkerTravel = (item) => {
  for (let i in locationData.value) {
    if (item.id === locationData.value[i].camera.id) {
      travelRoute.value.selectItem(Number(i), locationData.value[i]);
      break;
    }
  }
};
const getDataTravelMap = (selectedItem) => {
  let arr = [];
  let path = [];
  for (let i in locationData.value) {
    let item = locationData.value[i];
    let data = {
      lat: item.camera.latitude,
      lng: item.camera.longitude,
      type: MARKER_TYPE.DEFAULT,
      id: item.camera.id,
    };
    if (item.camera.id === lastCamera.value) {
      data.type = MARKER_TYPE.VANG;
    }
    if (selectedItem && selectedItem.camera.id === item.camera.id) {
      data.type = MARKER_TYPE.DO;
      data.infoWindow = getInfoWindow(
        item.camera.name,
        item.firstTime,
        item.camera.location,
        item.imageCropUrl,
        `searchCamera=${item.camera.id}&searchName=${selectedItem.profileUUID}&isTracing=true`,
        item.camera.id,
        item.directionType
      );
      if (item.camera.id === lastCamera.value) {
        data.type = MARKER_TYPE.VANG_SELECTED;
      }
    }
    arr.push(data);
    path.push([item.camera.longitude, item.camera.latitude]);
  }
  markersTravel.value = arr;
  flightPathTravel.value = path.reverse();
};
const getInfoWindow = (
  name,
  firstTime,
  address,
  img,
  query,
  cameraId,
  status
) => {
  let data = `<div class="content-wrapper p-16px">
            <img
              src="${img ? img : require('@/static/img/no-img.png')}"
              width="120"
              height="120"
              class="rounded-lg border-1 border-solid border-ems-gray300 mr-1 w-[120px] h-[120px] flex-shrink-0 object-cover"
            />
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold">${name}</span>
              <ul class="list-disc ml-20px flex flex-col gap-1 w-[300px]">
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.vi-tri'
                )}   ${address} </li>
             `;
  if (cameraId === lastCamera.value) {
    data =
      data +
      `<li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
        'object_tracing.time'
      )}:  ${formatDate(firstTime, 'DD/MM/YYYY - HH:mm:ss')}  </li>
        <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
          'object_tracing.status'
        )}:  ${
        status === EVENT_STATUS_VALUE.CHECKIN
          ? t('identification-event.checkin')
          : status === EVENT_STATUS_VALUE.CHECKOUT
          ? t('identification-event.checkout')
          : status === EVENT_STATUS_VALUE.APPEAR
          ? t('identification-event.appear')
          : status === EVENT_STATUS_VALUE.INTRUSION
          ? t('identification-event.intrusion')
          : ''
      } </li>`;
  } else {
    data =
      data +
      `<li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
        'object_tracing.time-appear'
      )} ${formatDate(firstTime, 'DD/MM/YYYY - HH:mm:ss')}  </li>`;
  }
  data =
    data +
    `</ul>
    <a href='/trace-management/identification-event?${query}' target="_blank" rel="noopener noreferrer nofollow"
              class="block w-[fit-content] rounded-full text-12px !text-ems-boTro4_600 px-16px py-3px border-1px border-solid border-ems-boTro4_600"
              onclick="event.stopPropagation();"> ${t(
                'object_tracing.view-event'
              )} </a>
            </div>
          </div>`;
  return data;
};
const selectLocation = (item) => {
  getDataTravelMap(item);
  if (travelMap.value) {
    travelMap.value.setMarker(markersTravel.value);
    travelMap.value.moveToLocation(item.camera.latitude, item.camera.longitude);
  }
};
watch(
  () => formState,
  async (val) => {
    await getData();
  },
  {
    deep: true,
  }
);
</script>

<style lang="scss">
.form-history-map .disabled-icon {
  cursor: not-allowed;
}
.form-history-map .shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}
.form-history-map .ant-select-selector {
  height: 34px !important;
  overflow-x: auto !important;
  padding-right: 0;
  padding-left: 4px;
}
.form-history-map .ant-select-selector .ant-select-selection-overflow {
  height: 34px !important;
  flex-wrap: nowrap;
}
.form-history-map .from-to-date .ant-row {
  margin: 0 !important;
}
.form-history-map .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.form-history-map .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.form-history-map .from-to-date .ant-row .ant-picker {
  border-radius: 0 !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
}
.form-history-map .from-to-date .ant-row .ant-form-item-control-input {
  min-height: unset !important;
}
.form-history-map .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  margin-right: 0.25rem;
}
.form-history-map .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .ant-select-selection-item-content {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.form-history-map .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .anticon-close {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.form-history-map .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px;
  right: 0.25rem;
}
.form-history-map ::-webkit-scrollbar:horizontal {
  height: 3px;
}
</style>
